@import "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";

:root {
  --main-bg-color: #1B307F;
  background-color: #d4d6e4;
}

body {
  margin: 0;
  background-color: #d4d6e4 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid darkgray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.card {
  margin-bottom: 5vh;
}
.listeChoixRdv {
  display: flex !important;
  align-items: start !important;
  justify-content: space-between !important;
}

canvas {
  border: solid black;
  max-width: 100vh;
  max-height: 100vh;
}

.conteneurSignature {
  display: flex;
  justify-content: center;
}

.conteneurBoutonSignature {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.gridConteneur {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.gridDiv {
  display: grid;
  grid-template-rows: max-content;
  justify-items: center;
}

.titleService {
  font-size: x-large;
  font-weight: 700;
}

.descService {
  font-size: large;
  opacity: 0.8;
}

.badgeGrid {
  display: grid;
  justify-items: end;
}

.customBadge {
  max-width: 50%;
  margin-bottom: 15%;
}

.leaflet-container {
  height: 50vh;
}

.buttonsLeafletContainer {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}


@media (min-width: 1080px) {

  .siteChoiceContainer {
    display: flex;
  }

  .interventionSiteSection {
    width: 50vw;
    height: 820px;
    padding-right: 2%;
  }

  .asideMapContainer {
    width: 50vw;
    height: 820px;
  }

  .leaflet-container {
    height: 100%;
  }
}

.detailModifRdvText {
  font-size: 20px;
}


.buttonCustomTable {
  margin-right: 1vw;
  color: #ffffff !important;
}

.btn-warning {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.customTableIcon {
  font-size: xx-large;
  color: rgba(0, 0, 0, 0.54);
}

.buttonIcon {
  margin-right: 1vw;
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}

.buttonIcon:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.customModal {
  justify-content: center !important;
  background-color: #512DA8;
  color: #ffffff;
}

@media (min-width: 992px) {
  .modal-lg {
    --bs-modal-width: 500px !important;
  }
}

.modal-body {
  font-size: medium !important;
}

.formCheckCustomTable > form-check-input:hover {
  cursor: default !important;
}

.bg-black {
  background-color: black;
}




