body {
    background-color: #fbfbfb;
}

@media (min-width: 992px) {
    .sidebar {
        height: unset!important;
    }
    .conteneur {
        padding-left: 250px;
        padding-right: 15px;
    }
    .boutonInstallApp {
        visibility: hidden;
    }
    .conteneurInstallBouton {
        display: none !important;
    }
    .conteneurBlock {
        display: flex !important;
    }
}


.customCard {
    margin: 25px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    background-color: #ffff;
    padding: 25px;
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 90px 0 0;
    /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px;
    z-index: 5;
    /* z-index: 600; */
}

.sidebar.show {
    height: 100vh;
}

@media (max-width: 992px) {
    .sidebar {
        width: 100%;
    }
    .boutonInstallApp {
        visibility: visible;
    }
    .qr-code-card {
        display:none !important
    }
    .conteneurInstallBouton {
        display: flex;
    }
    .conteneurBlock {
        display: block !important;
    }
}

@media (max-width: 520px) {
    .fc-toolbar-title {
        font-size: 1.3em !important;
    }

}

.sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}


.conteneur {
    padding-top: 15px;
}

.conteneurCard {
    min-height: 80vh;
}

.navbar {
    z-index: 5;

}

.servitysImg {
    border-radius: 20px;
    box-shadow: 0 0 0 8px rgba(0,0,0,.2);
}

.username {
    color: #ffff;
    font-weight: bold;
    font-size: 160%
}

.containerFlash {
    display: flex;
    font-size: larger;
    align-items: center;
    font-weight: bold;
}


.navBarSideBar {
    background-image: url(https://telerik.github.io/react-coffee-warehouse/static/media/header-bg.f1a95872.png);
}

.qrCode {
    width: 40vh !important;
}

.boutonInstallApp {
    width: auto;
}

.modal-bas {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal-backdrop {
    display:none;
}


/* TimeTable RDV Page */

.table-responsive {
    text-align:center;
}

.boutonTime {
    width: 100%;
}

.tabTimeTitle {
    text-align: center;
}

.dateSpan {
    opacity: 0.5;
}


